import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './BeforeVsAfter.module.css';

import Title from '../../../elements/Title';
import Statement from '../../../elements/Statement';
import Container from '../../../blocks/Container';
import FullWidthRow from '../../../blocks/FullWidthRow';

const BeforeVsAfter = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/before-after-statements.md/" } }) {
        edges {
          node {
            frontmatter {
              statements {
                title
                description
                type
              }
            }
          }
        }
      }
    }
  `);

  const { statements } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.beforeVsAfter}>
      <Container>
        <Container type="mobile">
          <FullWidthRow className={styles.beforeVsAfter__statementsMargin}>
            <Title>What Does <br className="mobile-br" /> Evergreen Look Like</Title>
            <Title className="mt-1">for Your Business?</Title>
          </FullWidthRow>
          <div className="row">
            {
              statements.map((statement, index) => (
                <div key={index} className={`${styles.beforeVsAfter__statementsMargin} col-xl-6 col-lg-6 col-12 d-flex justify-content-center`}>
                  <Statement node={statement} />
                </div>
              ))
            }
          </div>
        </Container>
      </Container>
      <div className={styles.beforeVsAfter__illustration} />
    </div>
  )
};

export default BeforeVsAfter;