import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './HelpfulTools.module.css';

import FullWidthRow from '../../../blocks/FullWidthRow';
import Title from '../../../elements/Title';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

import { capitalize } from '../../../../utils';

/* global Intercom */

const HelpfulTools = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/helpful-tools.md/" } }) {
        edges {
          node {
            frontmatter {
              tools {
                title
                image
                imageClass
                color
                href
                action
              }
            }
          }
        }
      }
    }
  `);

  const { tools } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.helpfulTools}>
      <Container type="big">
        <Container type="mobile">
          <FullWidthRow className={styles.helpfulTools__title}>
            <Title type="big">Education and Support</Title>
          </FullWidthRow>
          <div className="row">
            {
              tools.map((tool, index) => (
                <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-12 d-flex justify-content-center">
                  <a
                    {...tool.href ? { href: tool.href, target: '_blank', rel: 'noopener noreferrer' } : {}}
                    className={styles.toolContainer}
                    onClick={tool.action ? () => Intercom('show') : () => {}}
                    onKeyDown={tool.action ? () => Intercom('show') : () => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={styles.toolContainer__topPart}>
                      <div
                        className={`${styles.toolContainer__inner} ${styles.toolContainer__innerTop}`}
                        style={{ borderColor: tool.color }}
                      >
                        <Image name={tool.image} className={styles[`toolContainer__image${capitalize(tool.imageClass)}`]} />
                      </div>
                    </div>
                    <div className={styles.toolContainer__bottomPart}>
                      <div className={`${styles.toolContainer__inner} ${styles.toolContainer__innerBottom}`}>
                        {tool.title}
                      </div>
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default HelpfulTools;