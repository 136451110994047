import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './FeaturedIn.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Slider from 'react-slick';

const FeaturedIn = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/featured-in.md/" } }) {
        edges {
          node {
            frontmatter {
              images
            }
          }
        }
      }
    }
  `);

  const { images } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const sliderSettings = {
    className: 'brand-slider',
    dotsClass: 'slick-dots slider-dots',
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: '32%',
    slidesToShow: 1,
    speed: 500,
    dots: true,
    initialSlide: 2
  };

  return (
    <div className={styles.featuredIn}>
      <div className={styles.featuredIn__title}>Featured In</div>
      <Container type="big">
        <div className="row justify-content-center align-items-center d-lg-flex d-md-flex d-none">
          {
            images.map((image, index) => (
              <div key={index} className="col-xl-2 col-lg-2 col-md-2 col-12 d-flex justify-content-center">
                <Image name={image} className={styles.featuredIn__image} />
              </div>
            ))
          }
        </div>
        <div className={styles.featuredIn__mobileSlider}>
          <Slider {...sliderSettings}>
            {
              images.map((image, index) => (
                <div key={index} className="col-12 d-flex justify-content-center align-items-center h-100">
                  <Image name={image} className={styles.featuredIn__image} />
                </div>
              ))
            }
          </Slider>
        </div>
      </Container>
    </div>
  )
};

export default FeaturedIn;