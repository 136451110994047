import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './BlogPosts.module.css';

import FullWidthRow from '../../../blocks/FullWidthRow';
import Title from '../../../elements/Title';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Button from '../../../elements/Button';

const BlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blog-posts.md/" } }) {
        edges {
          node {
            frontmatter {
              posts {
                title
                description
                author
                image
                href
                type
              }
            }
          }
        }
      }
    }
  `);

  const { posts } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const mainPost = posts.find(post => post.type === 'main');

  return (
    <div className={styles.blogPosts}>
      <Container>
        <Container type="mobile">
          <FullWidthRow>
            <Title>Featured on the Blog</Title>
          </FullWidthRow>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-start">
              <a
                className={`${styles.blogPost} ${styles.blogPostMain}`}
                href={mainPost.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image name={mainPost.image} className={styles.blogPost__image} />
                <div className={`${styles.blogPost__previewText} mt-3 mb-1`}>{mainPost.author}</div>
                <div className={styles.blogPost__title}>{mainPost.title}</div>
              </a>
            </div>
            <div className={`${styles.blogPost__postsContainer} col-xl-6 col-lg-6 col-12`}>
              {
                posts.filter(post => post.type === 'standard').map((post, index) => (
                  <a key={index} href={post.href} target="_blank" rel="noopener noreferrer" className={styles.blogPost}>
                    <div className={styles.blogPost__textBlock}>
                      <div className={`${styles.blogPost__previewText} mb-1`}>{post.author}</div>
                      <div className={`${styles.blogPost__title} mb-3`}>{post.title}</div>
                      <div className={styles.blogPost__description}>{post.description}</div>
                    </div>
                    <div className={styles.blogPost__imageBlock}>
                      <Image name={post.image} className={styles.blogPost__image} />
                    </div>
                  </a>
                ))
              }
            </div>
          </div>
          <div className={`${styles.blogPost__allPostsButton} d-flex justify-content-center`}>
            <Button type="action" href="https://blog.deadlinefunnel.com/" target="_blank" rel="noopener noreferrer">
              Read More on the Blog
            </Button>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default BlogPosts;