import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Features.module.css';

import BlueBox from '../../../blocks/BlueBox';
import Container from '../../../blocks/Container';
import Title from '../../../elements/Title';
import FullWidthRow from '../../../blocks/FullWidthRow';
import Button from '../../../elements/Button';

const Features = ({ctaUrl = "https://start.deadlinefunnel.com/create-monthly"}) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/help-creators-text-blocks.md/" } }) {
        edges {
          node {
            frontmatter {
              blocks
            }
          }
        }
      }
    }
  `);

  const { blocks } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.features}>
      <BlueBox title="Explore the Platform" optionsFile="deadline-funnel-features.md" />
      <Container className="mt-xl">
        <Container type="mobile">
          <FullWidthRow>
            <Title>
              We Built Deadline <br className="mobile-br" />
              Funnel to <br className="desktop-br" />
              Help Creators <br className="mobile-br" />
              Make an Impact.
            </Title>
          </FullWidthRow>
          <div className={`${styles.features__firstTextContainer} row`}>
            <div className="col-xl-7 col-lg-7 col-12">
              {
                blocks.slice(0, 5).map((textBlock, index) => (
                  <div key={index} className={styles.features__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
                ))
              }
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex justify-content-center align-items-end">
              <div className={styles.features__image} />
            </div>
          </div>
          <FullWidthRow className="mt-2">
            {
              blocks.slice(5, 8).map((textBlock, index) => (
                <div key={index} className={styles.features__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
              ))
            }
          </FullWidthRow>
          <div className={`${styles.features__examplesContainer} row`}>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center">
              <div className={`${styles.features__floatingBarExample} ${styles.features__floatingBarExample1}`} />
              <div className={`${styles.features__floatingBarExample} ${styles.features__floatingBarExample2}`} />
              <div className={`${styles.features__floatingBarExample} ${styles.features__floatingBarExample3}`} />
            </div>
            <div className="col-xl-5 col-lg-5 col-12">
              {
                blocks.slice(8, 13).map((textBlock, index) => (
                  <div key={index} className={styles.features__textBlock} dangerouslySetInnerHTML={{ __html: textBlock }} />
                ))
              }
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button type="action" href={ctaUrl}>Get started</Button>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default Features;