import React from 'react';

import styles from './AboveTheFoldSection.module.css';

import Title from '../../../elements/Title';
import Subtitle from '../../../elements/Subtitle';
import Video from "../../../elements/Video";

import Container from '../../../blocks/Container';
import FullWidthRow from '../../../blocks/FullWidthRow';

import LeftGirl from '../../../../images/get-started/girl-left.svg';
import RightBoy from '../../../../images/get-started/boy-right.svg';
import MobileGirl from '../../../../images/get-started/girl-mobile.svg';

const AboveTheFoldSection = ({ctaUrl = "https://start.deadlinefunnel.com/create-monthly", funnel=false}) => (
  <div className={styles.aboveTheFoldSection}>
    <Container>
      <Container type="mobile">
        <FullWidthRow className={styles.mobileTextLeft}>
          <Title type="huge" className="mb-3">
            <span className={styles.aboveTheFoldSection__redUnderline}>Convert More</span> of Your
            <br className="desktop-and-tablet-br" /> Leads Into Customers
          </Title>
          {
            funnel == 'no-cc' &&
            <Subtitle type="big" className={styles.subTitle}>
              Get started today with your free trial of Deadline Funnel.<br className="desktop-and-tablet-br" />No credit card required!
            </Subtitle>
          }
          {
            !funnel &&
            <>
             <Subtitle type="big" className={styles.subTitle}>
              Deadline Funnel makes it easy to increase conversions and <br className="desktop-and-tablet-br" />generate more sales with authentic evergreen marketing.
            </Subtitle>
            
            <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center">
            <div className={`${styles.caseStudiesHeader__video}`}>
                <div className={styles.caseStudiesHeader__videoPreview}>
                  <Video
                    videoSrcURL="https://fast.wistia.net/embed/iframe/5zwsafg8ea"
                    videoTitle="Deadline Funnel"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
              </div>
              </>
          }
          {/* {
            !funnel &&
            <Subtitle type="big" className={styles.subTitle}>
              Deadline Funnel makes it easy to increase conversions and <br className="desktop-and-tablet-br" />generate more sales with authentic evergreen marketing.
            </Subtitle>
          } */}
          
          <div className={`${styles.aboveTheFoldSection__linksContainer} text-center`}>
            <a className={styles.aboveTheFoldSection__link} href={ctaUrl}>
              Start Your Free Trial Now!
            </a>
          </div>
        </FullWidthRow>
        <MobileGirl className={styles.aboveTheFoldSection__girlMobile} />
        <LeftGirl className={styles.aboveTheFoldSection__girl} />
        <RightBoy className={styles.aboveTheFoldSection__boy} />
      </Container>
    </Container>
  </div>
);

export default AboveTheFoldSection;