import React from 'react';
import Slider from 'react-slick';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './TrustedBy.module.css';

import Title from '../../../elements/Title';
import FullWidthRow from '../../../blocks/FullWidthRow';
import Image from '../../../elements/Image';
import Container from '../../../blocks/Container';
import Text from '../../../elements/Text';
import Button from '../../../elements/Button';

const TrustedBy = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/testimonials.md/" } }
      ) {
        edges {
          node {
            frontmatter {
              testimonials {
                image
                name
                occupation
                text
              }
            }
          }
        }
      }
    }
  `);

  const { testimonials } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const sliderSettings = {
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    centerPadding: '16%',
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <div className={styles.trustedBy}>
      <Container type="big">
        <FullWidthRow className={styles.trustedBy__titleMargin}>
          <Title className="pl-3 pr-3">
            Trusted by <br className="mobile-br" /> More than 3,000 Course{' '}
            <br className="mobile-br" /> Creators,
            <br className="desktop-br" /> Marketers, and{' '}
            <br className="mobile-br" /> Business Owners
          </Title>
        </FullWidthRow>
        <div className="row d-xl-flex d-lg-flex d-md-flex d-none">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12 mb-5">
              <Image name={testimonial.image} className="mb-4" />
              <div className={styles.trustedBy__testimonialName}>
                {testimonial.name}
              </div>
              <div className={styles.trustedBy__testimonialOccupation}>
                {testimonial.occupation}
              </div>
              <Text>{testimonial.text}</Text>
            </div>
          ))}
        </div>
        <div className={`${styles.trustedBy__mobileSlider} mb-5`}>
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="col-12">
                <Image name={testimonial.image} className="mb-4" />
                <div className={styles.trustedBy__testimonialName}>
                  {testimonial.name}
                </div>
                <div className={styles.trustedBy__testimonialOccupation}>
                  {testimonial.occupation}
                </div>
                <Text>{testimonial.text}</Text>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
      <Container type="mobile">
        <div className="d-flex justify-content-center">
          <Button type="action" href="/case-studies">
            Meet our customers
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default TrustedBy;
