import React, { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './BlueBox.module.css';

import FullWidthRow from '../FullWidthRow';
import Title from '../../elements/Title';
import Image from '../../elements/Image';
import Subtitle from '../../elements/Subtitle';
import Text from '../../elements/Text';
import Container from '../Container';
import LearnMoreLink from '../../elements/LearnMoreLink';

const BlueBox = ({ title, optionsFile }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/markdowns/"}}) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              options {
                image
                title
                text
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(() => (
    data.allMarkdownRemark.edges.find(({ node }) => node.fileAbsolutePath.includes(optionsFile))
  ), [optionsFile, data]);

  const { options } = match.node.frontmatter;

  return (
    <Container type="mobile">
      <Container type="big" className={styles.blueBox}>
        <FullWidthRow className={styles.blueBox__title}>
          <Title type="big" className="white-text">{title}</Title>
        </FullWidthRow>
        <div className="row">
          {
            options.map((option, index) => (
              <div key={index} className={`${styles.blueBox__itemContainer} col-xl-4 col-lg-4 col-md-4 col-12`}>
                <div className="d-flex justify-content-center align-items-center">
                  <Image name={option.image} className={styles.blueBox__image} />
                </div>
                <div className={styles.blueBox__option}>
                  <div className={`${styles.blueBox__separator} mb-md`} />
                  <Subtitle type="small" className={`${styles.blueBox__optionTitle} white-text`}>
                    {option.title}
                  </Subtitle>
                  <Text className="white-text">{option.text}</Text>
                </div>
              </div>
            ))
          }
        </div>
        <div className="row justify-content-center">
          <LearnMoreLink href="/features" />
        </div>
      </Container>
    </Container>
  )

};

export default BlueBox;