import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './WhatYouCanBuild.module.css';

import BlueBox from '../../../blocks/BlueBox';
import FullWidthRow from '../../../blocks/FullWidthRow';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';

const WhatYouCanBuild = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/statistic.md/" } }) {
        edges {
          node {
            frontmatter {
              statistic {
                number
                unit
                sign
                description
              }
            }
          }
        }
      }
    }
  `);

  const { statistic } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.whatYouCanBuild}>
      <BlueBox title="Start With These Blueprints" optionsFile="what-you-can-build.md" />
      <Container type="mobile">
        <FullWidthRow>
          <Title className={styles.whatYouCanBuild__title}>
            Deadline Funnel by <br className="mobile-br" />the Numbers
          </Title>
          <div className="d-flex justify-content-center">
            <Text className={`${styles.whatYouCanBuild__text} text-center`}>
              We're a small team of people who wake up every day to work for you - and together, we're building a marketing platform that helps thousands of course creators and business owners around the world.
            </Text>
          </div>
        </FullWidthRow>
        <Container type="big" className={styles.whatYouCanBuild__statCounterContainer}>
          <div className="row justify-content-center">
            {
              statistic.map((statisticRow, index) => (
                <div key={index} className="col-lg-3 col-xl-3 col-12 d-flex justify-content-center p-0">
                  <div className={styles.statCounterContainer}>
                    <div className="d-flex flex-xl-row flex-lg-row flex-column align-items-start justify-content-between">
                      <div className="d-flex flex-column align-items-center">
                        <div className={`${styles.whatYouCanBuild__statCounter} d-flex align-items-center`}>
                          <span className={styles.whatYouCanBuild__spacer}>{statisticRow.number}</span>
                          {
                            statisticRow.unit &&
                            <span className={`${styles.whatYouCanBuild__statCounterUnit} ${styles.whatYouCanBuild__spacer}`}>
                              {statisticRow.unit}
                            </span>
                          }
                          <span className={styles.whatYouCanBuild__statCounterPlus}>{statisticRow.sign}</span>
                        </div>
                        <div className={styles.whatYouCanBuild__statText}>
                          {statisticRow.description}
                        </div>
                      </div>
                      {index !== statistic.length - 1 && <div className={styles.whatYouCanBuild__separator} />}
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default WhatYouCanBuild;