import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './CourseCreator.module.css';

import FullWidthRow from '../../../blocks/FullWidthRow';
import Title from '../../../elements/Title';
import Subtitle from '../../../elements/Subtitle';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';
import Button from '../../../elements/Button';

const CourseCreator = ({ctaUrl = "https://start.deadlinefunnel.com/create-monthly"}) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/course-creator-statements.md/" } }) {
        edges {
          node {
            frontmatter {
              statements {
                number
                title
                description
                active
              }
            }
          }
        }
      }
    }
  `);

  const { statements } = data.allMarkdownRemark.edges[0].node.frontmatter;

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    statements.forEach((statement, index) => {
      new ScrollMagic.Scene({ triggerElement: `#statement${index + 1}`, offset: 0 })
        .setClassToggle(`#statement${index + 1}`, styles.courseCreatorSlide__statementActive)
        .addTo(controller);
    });

  }, [statements]);

  return (
    <div className={styles.courseCreatorSlide}>
      <Container>
        <Container type="mobile">
          <FullWidthRow>
            <Title>What's in an <br className="mobile-br" /> Evergreen Campaign?</Title>
          </FullWidthRow>
          <div className={`${styles.courseCreatorSlide__content} row`}>
            <div className={`${styles.courseCreatorSlide__descriptionContainer} col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-center`}>
              {
                statements.map((statement, index) => (
                  <div
                    key={index}
                    id={`statement${index + 1}`}
                    className={`${styles.courseCreatorSlide__statement}`}
                  >
                    <div className={styles.courseCreatorSlide__statementNumber}>{statement.number}</div>
                    <div className={styles.courseCreatorSlide__statementHeader}>
                      <Subtitle className={styles.courseCreatorSlide__text}>
                        {statement.title}
                      </Subtitle>
                    </div>
                    <div className={styles.courseCreatorSlide__statementSeparator} />
                    <div className={styles.courseCreatorSlide__statementText}>
                      <Text className="mt-2">{statement.description}</Text>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-center align-items-center">
              <div className={styles.courseCreatorSlide__image} />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button type="action" href={ctaUrl}>Get started</Button>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default CourseCreator;