import React from 'react';
import PropTypes from 'prop-types';

import styles from './Statement.module.css';

import Subtitle from '../Subtitle';
import Text from '../Text';
import Image from '../Image';

const Statement = ({ node }) => (
  <div className={styles.statement}>
    <Image name={`statement_${node.type}`} className={styles.statement__icon} />
    <div className={`${styles.statement__text} mb-3`}>
      <Subtitle className={styles.statement__title}>{node.title}</Subtitle>
    </div>
    <div className={styles.statement__text}>
      <Text>{node.description}</Text>
    </div>
  </div>
);

Statement.propType = {
  node: PropTypes.exact({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string
  }).isRequired
};

export default Statement;