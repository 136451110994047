import React from 'react';
import PropTypes from 'prop-types';

import styles from './Subtitle.module.css';

import { capitalize } from '../../../utils';

const Subtitle = ({ type, children, className }) => (
  <div className={`${styles.subtitle} ${className} ${styles[`subtitle${capitalize(type)}`]}`}>
    {children}
  </div>
);

Subtitle.defaultProps = {
  type: 'normal',
  className: ''
};

Subtitle.propsTypes = {
  type: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  children: PropTypes.node.required
};

export default Subtitle;