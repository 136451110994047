import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './FeaturedServices.module.css';

import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';

const FeaturedServices = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/featured-services.md/" } }) {
        edges {
          node {
            frontmatter {
              services {
                image
                title
                text
              }
            }
          }
        }
      }
    }
  `);

  const { services } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.featuredServices}>
      <Container type="big">
        <div className="row justify-content-around">
          {
            services.map((service, index) => (
              <div key={index} className={styles.featuredServices__col}>
                <Image name={service.image} className={styles.featuredServices__image} />
                <div className={styles.featuredServices__title}>{service.title}</div>
                <div className={styles.featuredServices__text}>{service.text}</div>
              </div>
            ))
          }
        </div>
      </Container>
    </div>
  )
};

export default FeaturedServices;